@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#root{
    font-family: "Poppins", serif;
}

@keyframes moveRightSlow {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  .bg-duotone-stripes {
    background-image: repeating-linear-gradient(
      45deg,
      rgb(236 72 153 / var(--tw-bg-opacity)) 0%,       /* Main bold color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 12%,      /* Covering most of the width */
      rgb(255 154 204) 12%,      /* Start of thin lighter line */
      rgb(255 154 204) 15%,      /* End of thin lighter line */
      rgb(236 72 153 / var(--tw-bg-opacity)) 15%,      /* Another section of main color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 27%,      /* End of main color */
      rgb(255 154 204) 27%,      /* Start of next thin lighter line */
      rgb(255 154 204) 30%,      /* End of next thin lighter line */
      rgb(236 72 153 / var(--tw-bg-opacity)) 30%,      /* Main color again */
      rgb(236 72 153 / var(--tw-bg-opacity)) 42%,      /* End of main color */
      rgb(255 154 204) 42%,      /* Another thin lighter line */
      rgb(255 154 204) 45%,      /* End of lighter color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 45%,      /* Continue main color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 57%,      /* End of main color */
      rgb(255 154 204) 57%,      /* Another light line */
      rgb(255 154 204) 60%,      /* End of light line */
      rgb(236 72 153 / var(--tw-bg-opacity)) 60%,      /* Main color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 72%,      /* End of main color */
      rgb(255 154 204) 72%,      /* Another thin lighter line */
      rgb(255 154 204) 75%,      /* End of light line */
      rgb(236 72 153 / var(--tw-bg-opacity)) 75%,      /* Continue main color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 87%,      /* End of main color */
      rgb(255 154 204) 87%,      /* Another light line */
      rgb(255 154 204) 90%,      /* End of light line */
      rgb(236 72 153 / var(--tw-bg-opacity)) 90%,      /* Continue main color */
      rgb(236 72 153 / var(--tw-bg-opacity)) 100%      /* Fill to 100% with no gaps */
    );
    background-size: 200% 100%;
  }
  
  .animate-move-right-slow {
    animation: moveRightSlow 10s linear infinite;
  }


  
.inputLoader {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  }

  .loader {
      width: 28px;
      height: 28px;
      border: 2px solid #699CFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 2s linear infinite;
      }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

.header-bg{
  background: -webkit-gradient(linear, left top, right top, from(#18101a), color-stop(50%, #431d4f), color-stop(60%, #2d1d60), to(#432066)) !important;
}


.swiper-pagination-bullet-active{
  background: #d12b8a !important;
}


.Toastify__progress-bar-theme--light {
  background: #facc15 !important; /* Solid yellow color */
}